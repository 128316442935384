import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useHtmlParser } from "../hooks/useHtmlParser"
import { BASE_BREADCRUMBS } from "../constants"

const SupplyChainPage = ({ data }) => {
  let supplyChain = data.allSupplyChain.nodes[0]?.content
  return (
    <Layout
      pageTitle="Supplier Resources"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/supply-chain", name: "Supply Chain" }]}
    >
      <Seo
        title="Supplier Resources | Typenex Medical"
        description="View supplier resources"
      />
      {
        <div>
          {useHtmlParser(supplyChain)}
        </div>
      }
    </Layout>
  )
}

export default SupplyChainPage

export const query = graphql`
  query {
    allSupplyChain {
      nodes {
        content
      }
    }
  }
`
